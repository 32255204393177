import { bindable, bindingMode, inject } from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { BaseListViewModel }             from 'base-list-view-model';
import { FilterFormSchema }              from 'modules/management/concessions/concessions-tree/lot-interventions/listing/filter-form-schema';
import { LotInterventionsRepository }    from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';

@inject(AppContainer, LotInterventionsRepository, FilterFormSchema, I18N, Downloader)
export class ListLotInterventions extends BaseListViewModel {
    headerTitle    = 'listing.management.concessions.lot-interventions';
    newRecordRoute = 'management.concessions.lot-interventions.create';
    repository;
    datatable;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) listingId;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterSchema;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) displayTree;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param downloader
     */
    constructor(appContainer, repository, filterFormSchema, i18N, downloader) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.i18N             = i18N;
        this.downloader       = downloader;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this, this.filterModel.parent_id, this.filterModel.parent_key);
        this.listingId    = 'management-concessions-lot-interventions-listing';

        this.defineDatatable();
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.displayTree = !this.displayTree;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.lot-interventions.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.lot-interventions.manage', 'management.concessions.concessions.lot-interventions.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.lot-interventions.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.lot-interventions.manage', 'management.concessions.concessions.lot-interventions.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.lot-interventions.manage', 'management.concessions.concessions.lot-interventions.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'number',
                    name:  'lot_interventions.number',
                    title: 'form.field.number',
                },
                {
                    data:  'designation',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.designation',
                },
                {
                    data:            'start_date',
                    name:            'lot_interventions.start_date',
                    title:           'form.field.start-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'finish_date',
                    name:            'lot_interventions.finish_date',
                    title:           'form.field.finish-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'created_at',
                    name:            'lot_interventions.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }
}
