import { EventAggregator }      from 'aurelia-event-aggregator';
import { inject }               from 'aurelia-framework';
import { I18N }                 from 'aurelia-i18n';
import { BaseFilterFormSchema } from 'resources/classes/base-filter-form-schema';
import { SessionStorage }       from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        parent_id:       null,
        parent_key:      null,
        code:            null,
        acronym:         null,
        description:     null,
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     */
    constructor(storage, eventAggregator, i18n) {
        super(storage, eventAggregator, i18n);
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel, parentId, parentKey) {
        this.modelDefaults.parent_id  = parentId;
        this.modelDefaults.parent_key = parentKey;
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.number = {
            type:     'text',
            key:      'number',
            label:    'form.field.lot-intervention-number',
            size:     6,
            required: false,
        };

        this.designation = {
            type:     'text',
            key:      'designation',
            label:    'form.field.designation',
            size:     6,
            required: false,
        };

        this.start_date = {
            type:     'material-ui-date-picker',
            key:      'start_date',
            label:    'form.field.start-date',
            size:     6,
            required: false,
        };

        this.finish_date = {
            type:     'material-ui-date-picker',
            key:      'finish_date',
            label:    'form.field.finish-date',
            size:     6,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     6,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     6,
            required: false,
        };

        this.created_by = {
            type:     'users-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     6,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', {
                listingId:      viewModel.listingId,
                ignoredFilters: ['parent_id', 'parent_key'],
            }),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.number, this.designation],
            [this.start_date, this.finish_date],
            [this.created_at_from, this.created_at_to],
            [this.created_by],
            [this.buttons],
        ];
    }
}
